import React, { FunctionComponent } from "react";

import { Product as ProductModel } from "../../model/product";

// TODO use CSS modules
import "./Product.scss";

interface ProductProps {
  product: ProductModel;
  onClick: (id: string) => void; // eslint-disable-line no-unused-vars
}

export const Product: FunctionComponent<ProductProps> = ({ product, onClick }) => {
  const emitOnClick = (): void => onClick(product.id);

  return (
    <div onClick={emitOnClick}>
      <figure className="product">
        <img src={product.thumbnailUrl} alt={product.id} />
        <figcaption>{product.id}</figcaption>
      </figure>
    </div>
  );
};
