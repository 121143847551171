import React, { FunctionComponent, useState } from "react";

import { ProductCategory } from "./product-category/ProductCategory";
import { CategoryId } from "../model/category-id";

// TODO use CSS modules
import "./ProductCategories.scss";

const CATEGORIES = [
  "products.mvq.category.all",
  "products.mvq.category.vehicles",
  "products.mvq.category.refrigeration",
  "products.mvq.category.baking",
  "products.mvq.category.heating",
  "products.mvq.category.construction",
  "products.mvq.category.welding-and-plastics",
  "products.mvq.category.food",
  "products.mvq.category.furniture",
  "products.mvq.category.shower",
  "products.mvq.category.lightning",
  "products.mvq.category.electrical",
] as const;

interface ProductCategoriesProps {
  onCategoryClicked: Function;
}

// interface ProductCategoriesState {
//   selectedCategory: string | null;
// }

const ProductCategories: FunctionComponent<ProductCategoriesProps> = ({ onCategoryClicked }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(CATEGORIES[0]);

  const handleClick = (index: CategoryId): void => {
    onCategoryClicked(index);
    setSelectedCategory(CATEGORIES[index]);
  };

  return (
    <section className="product-categories">
      {CATEGORIES.map((categoryName, categoryIndex) => (
        <ProductCategory
          key={categoryName}
          categoryName={categoryName}
          categoryIndex={categoryIndex}
          isSelected={categoryName === selectedCategory}
          onClick={handleClick}
        />
      ))}
    </section>
  );
};

export default ProductCategories;
