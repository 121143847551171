import React, { FunctionComponent } from "react";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import { CategoryId } from "../../model/category-id";

// TODO use CSS modules
import "./ProductCategory.scss";

interface ProductCategoryProps {
  onClick: (index: CategoryId) => void; // eslint-disable-line no-unused-vars
  categoryName: string;
  categoryIndex: CategoryId;
  isSelected: boolean;
}

export const ProductCategory: FunctionComponent<ProductCategoryProps> = ({
  onClick,
  categoryName,
  categoryIndex,
  isSelected,
}) => {
  const emitOnClick = (): void => onClick(categoryIndex);

  return (
    <span className={isSelected ? "active" : ""} onClick={emitOnClick}>
      <FormattedMessage id={categoryName} />
    </span>
  );
};
