import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Grid, GridCellProps } from "react-virtualized/dist/commonjs/Grid";

import Lightbox from "react-image-lightbox";

import { useWindowSize } from "../../hooks/useWindowSize";
import { Product as ProductComponent } from "./product/Product";
import { Product } from "../model/product";

// TODO use CSS modules
import "./ProductsGrid.scss";
import "react-image-lightbox/style.css";

interface ProductsGridProps {
  products: Product[];
}

export const ProductsGrid: FunctionComponent<ProductsGridProps> = ({ products }) => {
  const [selectedProductId, setSelectedProductId] = useState(undefined);
  const openProductModal = (id: string) => setSelectedProductId(id as any);
  const closeProductModal = () => setSelectedProductId(undefined);

  const gridComponent = useRef(null);
  useEffect(() => {
    if (gridComponent.current) {
      // @ts-ignore
      gridComponent.current.scrollToPosition({ scrollLeft: 0, scrollTop: 0 });
    }
  }, [products]);

  const selectedProduct = selectedProductId ? products.find((product) => product.id === selectedProductId) : undefined;

  const [windowWidth, windowHeight] = useWindowSize();

  const columnsCount = Math.floor((windowWidth - 20) / 150);
  const rowsCount = Math.ceil(products.length / columnsCount);

  const renderCell = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    const columnsCount = Math.floor((windowWidth - 20) / 120);
    const index = rowIndex * columnsCount + columnIndex;
    const product = products[index];

    if (product) {
      return (
        <div key={key} style={style}>
          <ProductComponent product={product} key={key} onClick={openProductModal} />
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Grid
        ref={gridComponent}
        style={{ margin: "auto" }}
        cellRenderer={renderCell}
        columnWidth={150}
        columnCount={columnsCount}
        height={windowHeight / 1.5}
        rowHeight={192}
        rowCount={rowsCount}
        width={columnsCount * 150 + 20}
      />
      {!!selectedProduct && <Lightbox mainSrc={selectedProduct.imageUrl} onCloseRequest={closeProductModal} />}
    </>
  );
};
