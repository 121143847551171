import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";
import { FaCheck, FaTimes, FaRegFilePdf } from "react-icons/fa";

import ProductCategories from "../shared/products/product-categories/ProductCategories";
import { ProductsGrid } from "../shared/products/products-grid/ProductsGrid";
import { CategoryId } from "../shared/products/model/category-id";

import mvqFirstFile from "../assets/downloads/mvq/tests-results-pca.pdf";
import mvqSecondFile from "../assets/downloads/mvq/tests-results-exova.pdf";
import bentosilSwellsilFile from "../assets/downloads/krajowa-ocena-techniczna-bentosil-swellsil.pdf";
import bentosilFile from "../assets/downloads/bentosil/bentosil-ulotka-montaz.pdf";
import swellsilFile from "../assets/downloads/swellsil/swellsil-instrukcja-stosowania.pdf";
import silbutFirstFile from "../assets/downloads/silbut-uni/silbut-uni-aprobata-itb.pdf";
import silbutSecondFile from "../assets/downloads/silbut-uni/silbut-uni-karta-produktu.pdf";

// TODO use CSS modules
import "./products.scss";

import Layout from "../shared/layout";
import SEO from "../shared/seo";

// TODO move to another file
import categories from "../assets/categories.json";

const productsByCategory = new Map();
Object.keys(categories).forEach((key: string) => {
  productsByCategory.set(Number(key), categories[key]);
});
const generateProduct = (id: string) => ({
  id,
  thumbnailUrl: `/products/thumbs/${id}.png`,
  imageUrl: `/products/large/${id}.png`,
});
const allProducts = productsByCategory.get(0).map(generateProduct);

const scrollToElement = (id: string) => {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  });
};

const ProductsPage: FunctionComponent = ({ location }) => {
  const { formatMessage, locale } = useIntl();

  const [products, setProducts] = useState(allProducts);

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const id = hash.replace("#", "");
      scrollToElement(id);
    }
  });

  const filterProductsByCategory = (category: CategoryId) => {
    const productIds = productsByCategory.get(category);
    const newProducts = productIds ? productIds.map(generateProduct) : [];
    setProducts(newProducts);
  };

  const seoDescription =
    formatMessage({ id: "products.stocks-description" }) +
    " " +
    formatMessage({ id: "about.products.mvq.title" }) +
    ", " +
    formatMessage({ id: "about.products.tpe.title" }) +
    ", " +
    formatMessage({ id: "about.products.bentosil.title" }) +
    ", " +
    formatMessage({ id: "about.products.swellsil.title" }) +
    ", " +
    formatMessage({ id: "about.products.silbut-uni.title" }) +
    ".";

  return (
    <Layout>
      <SEO title={formatMessage({ id: "page.products" })} description={seoDescription} lang={locale} />
      <section className="products-section" id="toc">
        <h1 className="text-center">
          <FormattedMessage id="about.products.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-xs">
                <FormattedMessage id="products.stocks-description" />
              </p>
              <ul>
                <li className="lowercase-first-letter">
                  <a href="#mvq">
                    <FormattedMessage id="about.products.mvq.title" />
                  </a>
                </li>
                <li className="lowercase-first-letter">
                  <a href="#tpe">
                    <FormattedMessage id="about.products.tpe.title" />
                  </a>
                </li>
                <li className="lowercase-first-letter">
                  <a href="#bentosil">
                    <FormattedMessage id="about.products.bentosil.title" />
                  </a>
                </li>
                <li className="lowercase-first-letter">
                  <a href="#swellsil">
                    <FormattedMessage id="about.products.swellsil.title" />
                  </a>
                </li>
                <li className="lowercase-first-letter">
                  <a href="#silbut-uni">
                    <FormattedMessage id="about.products.silbut-uni.title" />
                  </a>
                </li>
              </ul>
              <p className="cs_bot-margin-xs">
                <FormattedMessage id="products.click-category" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="products-section" id="mvq">
        <h1 className="text-center">
          <FormattedMessage id="about.products.mvq.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.mvq.description.1" />
              </p>
              <p className="cs_bot-margin-m justify">
                <FormattedMessage id="products.mvq.description.2" />
              </p>
            </div>
          </div>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="products.basic-properties" />
          </h2>
          <div className="cs_row">
            <div className="cs_col">
              <table className="table table-striped table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="products.parameter" />
                    </th>
                    <th scope="col" className="text-right">
                      <FormattedMessage id="products.range" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.unit" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.method" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="products.density" />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={1.1} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={1.19} />
                    </td>
                    <td>g/ml</td>
                    <td>DIN&nbsp;53&nbsp;497&nbsp;A</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.hardness" />
                    </td>
                    <td className="text-right">20&nbsp;-&nbsp;90</td>
                    <td>Shore&nbsp;A</td>
                    <td>DIN&nbsp;53&nbsp;505</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.tensile-strength" />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={6.5} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={11.8} />
                    </td>
                    <td>
                      N/mm<sup>2</sup>
                    </td>
                    <td>DIN&nbsp;53&nbsp;504-S1</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.elongation" />
                    </td>
                    <td className="text-right">300&nbsp;-&nbsp;1100</td>
                    <td>%</td>
                    <td>DIN&nbsp;53&nbsp;504-S1</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.tear-strength" />
                    </td>
                    <td className="text-right">14&nbsp;-&nbsp;26</td>
                    <td>N/mm</td>
                    <td>ASTM&nbsp;D&nbsp;624&nbsp;B</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.rebound-resilience" />
                    </td>
                    <td className="text-right">40&nbsp;-&nbsp;60</td>
                    <td>%</td>
                    <td>DIN&nbsp;53&nbsp;512</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.compression-set" />
                    </td>
                    <td className="text-right">12&nbsp;-&nbsp;60</td>
                    <td>%</td>
                    <td>DIN&nbsp;53&nbsp;517</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage
                  id="products.time-in-temp"
                  tagName="em"
                  values={{
                    time: "22h",
                    temperature: "175",
                  }}
                />
              </p>
            </div>
          </div>
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-m justify">
                <FormattedMessage id="products.mvq.colors.intro" />
              </p>
            </div>
          </div>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="products.mvq.colors.title" />
          </h2>
          <div className="cs_row">
            <div className="cs_col">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="products.color" />
                    </th>
                    <th scope="col">
                      <span className="d-none d-sm-inline">
                        <FormattedMessage id="products.mvq.colors.ral-equivalent" />
                      </span>
                      <span>RAL</span>
                    </th>
                    <th scope="col">BfR*</th>
                    <th scope="col">FDA*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="ral-1016">
                    <td>Yellow</td>
                    <td>1016</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-1021">
                    <td>Yellow</td>
                    <td>1021</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-2004">
                    <td>Orange</td>
                    <td>2004</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-3000">
                    <td>Red</td>
                    <td>3000</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-3013">
                    <td>Reddish brown</td>
                    <td>3013</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-4001">
                    <td>Violet</td>
                    <td>4001</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-5002">
                    <td>Ultramarine blue</td>
                    <td>5002</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-5010">
                    <td>Dark blue</td>
                    <td>5010</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-5015">
                    <td>Pale blue</td>
                    <td>5015</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-6017">
                    <td>Green</td>
                    <td>6017</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-6032">
                    <td>Green</td>
                    <td>6032</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-7040">
                    <td>Gray</td>
                    <td>7040</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-8003">
                    <td>Light brown</td>
                    <td>8003</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-8015">
                    <td>Dark brown</td>
                    <td>8015</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-9005">
                    <td>Jet black</td>
                    <td>9005</td>
                    <td>
                      <FaTimes />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-9005">
                    <td>Jet black</td>
                    <td>9005F</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-9006">
                    <td>White aluminum (silver)</td>
                    <td>9006</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-9010">
                    <td>White</td>
                    <td>9010</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                  <tr className="ral-9011">
                    <td>Black</td>
                    <td>9011</td>
                    <td>
                      <FaTimes />
                    </td>
                    <td>
                      <FaTimes />
                    </td>
                  </tr>
                  <tr className="ral-9017">
                    <td>Black</td>
                    <td>9017</td>
                    <td>
                      <FaCheck />
                    </td>
                    <td>
                      <FaCheck />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.mvq.colors.food-industry-compliance" tagName="em" />
              </p>
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.mvq.additives" />
              </p>
              <p className="cs_bot-margin-m justify">
                <FormattedMessage id="products.mvq.electrical.intro" />
              </p>
            </div>
          </div>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="products.mvq.electrical.title" />
          </h2>
          <div className="cs_row">
            <div className="cs_col">
              <table className="table table-striped table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="products.parameter" />
                    </th>
                    <th scope="col" className="text-right">
                      <FormattedMessage id="products.range" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.unit" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.method" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.resistance" tagName="td" />
                    <td className="text-right">
                      10<sup>15</sup>&nbsp;-&nbsp;10<sup>16</sup>
                    </td>
                    <td>Ω&nbsp;·&nbsp;cm</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.resistivity" tagName="td" />
                    <td className="text-right">
                      10<sup>12</sup>&nbsp;-&nbsp;10<sup>13</sup>
                    </td>
                    <td>Ω</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.dielectric-strength" tagName="td" />
                    <td className="text-right">20&nbsp;-&nbsp;30</td>
                    <td>kV/mm</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.dielectric-constant" tagName="td" />
                    <td className="text-right">
                      <FormattedNumber value={2.7} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={3.3} />
                    </td>
                    <td>-</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.dielectric-loss-factor" tagName="td" />
                    <td className="text-right">
                      30&nbsp;·&nbsp;10<sup>-4</sup> - 80&nbsp;·&nbsp;10
                      <sup>-4</sup>
                    </td>
                    <td>-</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                  <tr>
                    <FormattedMessage id="products.mvq.electrical.lower-calorific-value" tagName="td" />
                    <td className="text-right">
                      <FormattedNumber value={14.2} /> - <FormattedNumber value={16.9} />
                    </td>
                    <td>kJ/g</td>
                    <td>VDE&nbsp;0303</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <em>* (50&nbsp;Hz,&nbsp;25&nbsp;ºC)</em>
              </p>
            </div>
          </div>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="common.download-section" />
          </h2>
          <p className="cs_bot-margin-xs text-center">
            <a
              href={mvqFirstFile}
              className="btn btn-lg btn-outline-secondary pdf-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaRegFilePdf />
              <FormattedMessage id="products.mvq.download-pl-report" />
            </a>
          </p>
          <p className="cs_bot-margin-m text-center">
            <a
              href={mvqSecondFile}
              className="btn btn-lg btn-outline-secondary pdf-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaRegFilePdf />
              <FormattedMessage id="products.mvq.download-en-report" />
            </a>
          </p>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="common.photos" />
          </h2>
          <div className="cs_box-container round cs_bot-margin-s">
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-1.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-2.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-3.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-4.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-5.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/profiles-6.jpg"
                alt="Wytłaczane wyroby silikonowe - profil"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage src="../assets/images/products/mvq/tube-1.jpg" alt="Wytłaczane wyroby silikonowe - wąż" />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage src="../assets/images/products/mvq/tube-2.jpg" alt="Wytłaczane wyroby silikonowe - wąż" />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage src="../assets/images/products/mvq/tube-3.jpg" alt="Wytłaczane wyroby silikonowe - wąż" />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/foamy-1.jpg"
                alt="Wytłaczane wyroby z silikonu spienionego"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/foamy-2.jpg"
                alt="Wytłaczane wyroby z silikonu spienionego"
              />
            </div>
            <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
              <StaticImage
                src="../assets/images/products/mvq/foamy-3.jpg"
                alt="Wytłaczane wyroby z silikonu spienionego"
              />
            </div>
          </div>
        </div>
        <h2 className="text-center">
          <FormattedMessage id="products.mvq.available-profiles" />
        </h2>
        <ProductCategories onCategoryClicked={filterProductsByCategory} />
        <ProductsGrid products={products} />
      </section>
      <section className="products-section" id="tpe">
        <h1 className="text-center">
          <FormattedMessage id="about.products.tpe.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.tpe.description.1" />
              </p>
              <p className="cs_bot-margin-m justify">
                <FormattedMessage id="products.tpe.description.2" />
              </p>
            </div>
          </div>
          <h2 className="text-center cs_bot-margin-s">
            <FormattedMessage id="products.basic-properties" />
          </h2>
          <div className="cs_row">
            <div className="cs_col">
              <table className="table table-striped table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">
                      <FormattedMessage id="products.parameter" />
                    </th>
                    <th scope="col" className="text-right">
                      <FormattedMessage id="products.range" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.unit" />
                    </th>
                    <th scope="col">
                      <FormattedMessage id="products.method" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="products.density" />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={1.18} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={1.21} />
                    </td>
                    <td>g/ml</td>
                    <td>ISO&nbsp;1183</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.hardness" />
                    </td>
                    <td className="text-right">60&nbsp;-&nbsp;70</td>
                    <td>Shore&nbsp;A</td>
                    <td>ISO&nbsp;505</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.tpe.stress-at-elongation" values={{ percent: 20 }} />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={1.0} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={1.5} />
                    </td>
                    <td>MPa</td>
                    <td>ISO&nbsp;37</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.tpe.stress-at-elongation" values={{ percent: 100 }} />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={2.3} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={2.5} />
                    </td>
                    <td>MPa</td>
                    <td>ISO&nbsp;37</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.tpe.tensile-strength" />
                    </td>
                    <td className="text-right">
                      <FormattedNumber value={7.7} />
                      &nbsp;-&nbsp;
                      <FormattedNumber value={8.3} />
                    </td>
                    <td>MPa</td>
                    <td>ISO&nbsp;37</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.elongation" />
                    </td>
                    <td className="text-right">580&nbsp;-&nbsp;640</td>
                    <td>%</td>
                    <td>ISO&nbsp;37</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="products.compression-set" />
                    </td>
                    <td className="text-right">12</td>
                    <td>%</td>
                    <td>ISO&nbsp;R&nbsp;815</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage
                  id="products.time-in-temp"
                  tagName="em"
                  values={{
                    time: "22h",
                    temperature: "23",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="products-section" id="bentosil">
        <h1 className="text-center">
          <FormattedMessage id="about.products.bentosil.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.bentosil.description.1" />
              </p>
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.bentosil.description.2" />
              </p>
              <div className="cs_bot-margin-s justify">
                <FormattedMessage id="products.bentosil.description.3" />
                <ul>
                  <FormattedMessage id="products.bentosil.usage.1" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.2" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.3" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.4" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.5" tagName="li" />
                </ul>
              </div>
              <div className="cs_bot-margin-m justify">
                <FormattedMessage id="products.bentosil.description.4" />
                <ul>
                  <FormattedMessage id="products.bentosil.usage.1" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.2" tagName="li" />
                  <FormattedMessage id="products.bentosil.usage.3" tagName="li" />
                </ul>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="products.basic-properties" />
              </h2>
              <div className="cs_row">
                <div className="cs_col">
                  <table className="table table-striped table-bordered table-responsive-sm">
                    <thead>
                      <tr>
                        <th scope="col">
                          <FormattedMessage id="products.parameter" />
                        </th>
                        <th scope="col" className="text-right">
                          <FormattedMessage id="products.requirements" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="products.unit" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <FormattedMessage id="products.acceptable-dimension-deviations" tagName="td" />
                        <td className="text-right">&plusmn;&nbsp;10</td>
                        <td>%</td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.density" tagName="td" />
                        <td className="text-right">
                          <FormattedNumber value={1.5} />
                          &nbsp;&plusmn;&nbsp;10%
                        </td>
                        <td>
                          g/cm<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.swelling-pressure" tagName="td" />
                        <td className="text-right">&ge;&nbsp;600</td>
                        <td>kPa</td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.water-tightness" tagName="td" />
                        <td className="text-right">
                          &ge;&nbsp;
                          <FormattedNumber value={0.5} />
                        </td>
                        <td>MPa</td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.water-tightness-after-drying" tagName="td" />
                        <td className="text-right">
                          &ge;&nbsp;
                          <FormattedNumber value={0.5} />
                        </td>
                        <td>MPa</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cs_row">
                <div className="cs_col">
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.thickness-width-diameter" tagName="em" />
                  </p>
                  <p className="cs_bot-margin-m justify">
                    <FormattedMessage id="products.lack-of-leakage" tagName="em" />
                  </p>
                </div>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="common.download-section" />
              </h2>
              <p className="cs_bot-margin-xs text-center">
                <a
                  href={bentosilSwellsilFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.download-itb-approval" />
                </a>
              </p>
              <p className="cs_bot-margin-m text-center">
                <a
                  href={bentosilFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.bentosil.download-well-installation-flyer" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="products-section" id="swellsil">
        <h1 className="text-center">
          <FormattedMessage id="about.products.swellsil.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.swellsil.description.1" />
              </p>
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.swellsil.description.2" />
              </p>
              <div className="cs_bot-margin-m justify">
                <FormattedMessage id="products.swellsil.usage.title" />
                <ul>
                  <FormattedMessage id="products.swellsil.usage.1" tagName="li" />
                  <FormattedMessage id="products.swellsil.usage.2" tagName="li" />
                  <FormattedMessage id="products.swellsil.usage.3" tagName="li" />
                  <FormattedMessage id="products.swellsil.usage.4" tagName="li" />
                </ul>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="products.basic-properties" />
              </h2>
              <div className="cs_row">
                <div className="cs_col">
                  <table className="table table-striped table-bordered table-responsive-sm">
                    <thead>
                      <tr>
                        <th colSpan={2} scope="col">
                          <FormattedMessage id="products.parameter" />
                        </th>
                        <th scope="col" className="text-right">
                          <FormattedMessage id="products.requirements" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="products.unit" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.acceptable-dimension-deviations" />
                        </td>
                        <td className="text-right">&plusmn;&nbsp;10</td>
                        <td>%</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.density" />
                        </td>
                        <td className="text-right">
                          <FormattedNumber value={1.2} />
                          &nbsp;&plusmn;&nbsp;10%
                        </td>
                        <td>
                          g/cm<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.swelling-pressure" />
                        </td>
                        <td className="text-right">&ge;&nbsp;700</td>
                        <td>kPa</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.water-tightness" />
                        </td>
                        <td className="text-right">
                          &ge;&nbsp;
                          <FormattedNumber value={0.5} />
                        </td>
                        <td>MPa</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.water-tightness-after-drying" />
                        </td>
                        <td className="text-right">
                          &ge;&nbsp;
                          <FormattedNumber value={0.5} />
                        </td>
                        <td>MPa</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cs_row">
                <div className="cs_col">
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.thickness-width-diameter" tagName="em" />
                  </p>
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.lack-of-leakage" tagName="em" />
                  </p>
                </div>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="common.photos" />
              </h2>
              <div className="cs_box-container round cs_bot-margin-s">
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-1.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-2.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-3.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-4.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-5.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-6.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-7.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-8.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-9.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-10.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/swellsil/swellsil-11.jpg"
                    alt="SWELLSIL zdjęcie produktu"
                  />
                </div>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="common.download-section" />
              </h2>
              <p className="cs_bot-margin-xs text-center">
                <a
                  href={bentosilSwellsilFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.download-itb-approval" />
                </a>
              </p>
              <p className="cs_bot-margin-m text-center">
                <a
                  href={swellsilFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.download-instructions-for-use" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="products-section" id="silbut-uni">
        <h1 className="text-center">
          <FormattedMessage id="about.products.silbut-uni.title" />
        </h1>
        <div className="cs_container">
          <div className="cs_row">
            <div className="cs_col">
              <p className="cs_bot-margin-s justify">
                <FormattedMessage id="products.silbutuni.description" />
              </p>
              <div className="cs_bot-margin-s justify">
                <FormattedMessage id="products.silbutuni.properties" />
                <ul>
                  <FormattedMessage id="products.silbutuni.property.1" tagName="li" />
                  <FormattedMessage id="products.silbutuni.property.2" tagName="li" />
                  <FormattedMessage id="products.silbutuni.property.3" tagName="li" />
                  <FormattedMessage id="products.silbutuni.property.4" tagName="li" />
                  <FormattedMessage id="products.silbutuni.property.5" tagName="li" />
                  <FormattedMessage id="products.silbutuni.property.6" tagName="li" />
                </ul>
              </div>
              <div className="cs_bot-margin-m justify">
                <FormattedMessage id="products.silbutuni.usages.title" />
                <ul>
                  <FormattedMessage id="products.silbutuni.usages.1" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.2" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.3" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.4" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.5" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.6" tagName="li" />
                  <FormattedMessage id="products.silbutuni.usages.7" tagName="li" />
                </ul>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="products.basic-properties" />
              </h2>
              <div className="cs_row">
                <div className="cs_col">
                  <table className="table table-striped table-bordered table-responsive-sm">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>
                          <FormattedMessage id="products.parameter" />
                        </th>
                        <th scope="col" className="text-right">
                          <FormattedMessage id="products.requirements" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="products.unit" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.acceptable-dimension-deviations" />
                        </td>
                        <td className="text-right">&plusmn;&nbsp;10</td>
                        <td>%</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.density" />
                        </td>
                        <td className="text-right">
                          <FormattedNumber value={1.4} />
                          &nbsp;&plusmn;&nbsp;10%
                        </td>
                        <td>
                          g/cm<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <FormattedMessage id="products.water-tightness" />
                        </td>
                        <td className="text-right">
                          &ge;&nbsp;
                          <FormattedNumber value={0.2} />
                        </td>
                        <td>MPa</td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          <FormattedMessage id="products.silbutuni.adhesion-to-concrete" />
                        </td>
                        <FormattedMessage id="products.silbutuni.expressed-through-force" tagName="td" />
                        <td className="text-right">&gt;&nbsp;55</td>
                        <td>N</td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.silbutuni.expressed-through-elongation" tagName="td" />
                        <td className="text-right">&gt;&nbsp;20</td>
                        <td>%</td>
                      </tr>
                      <tr>
                        <td rowSpan={2} className="align-middle">
                          <FormattedMessage id="products.silbutuni.adhesion-to-concrete-after-water" />
                        </td>
                        <FormattedMessage id="products.silbutuni.expressed-through-force" tagName="td" />
                        <td className="text-right">&gt;&nbsp;55</td>
                        <td>N</td>
                      </tr>
                      <tr>
                        <FormattedMessage id="products.silbutuni.expressed-through-elongation" tagName="td" />
                        <td className="text-right">&gt;&nbsp;10</td>
                        <td>%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cs_row">
                <div className="cs_col">
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.thickness-width-diameter" tagName="em" />
                  </p>
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.lack-of-leakage" tagName="em" />
                  </p>
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage
                      id="products.silbutuni.in-temp"
                      tagName="em"
                      values={{ temperature: 23, precision: 2 }}
                    />
                  </p>
                  <p className="cs_bot-margin-s justify">
                    <FormattedMessage id="products.silbutuni.at-first-sign-of-loosening" tagName="em" />
                  </p>
                </div>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="common.installation" />
              </h2>
              <div className="cs_box-container round cs_bot-margin-s">
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-1.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-2.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-3.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-4.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-5.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
                <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                  <StaticImage
                    src="../assets/images/products/silbutuni/silbut-uni-6.jpg"
                    alt="SILBUT-UNI zdjęcie z montażu"
                  />
                </div>
              </div>
              <h2 className="text-center cs_bot-margin-s">
                <FormattedMessage id="common.download-section" />
              </h2>
              <p className="cs_bot-margin-xs text-center">
                <a
                  href={silbutFirstFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.download-itb-approval" />
                </a>
              </p>
              <p className="cs_bot-margin-m text-center">
                <a
                  href={silbutSecondFile}
                  className="btn btn-lg btn-outline-secondary pdf-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  <FormattedMessage id="products.download-product-datasheet" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsPage;
